import React from 'react';
import { PageSecteur } from '@src/ui';
import { usePageList } from '@src/hooks/page-list';

const Page: React.FC = () => {
  const pages = usePageList();
  const currentPageData = pages.find(p => p.id === 'contact');

  return (
    <PageSecteur
      city="Bourget-du-Lac"
      prefix="au"
      image={currentPageData?.image}
    />
  );
};

export default Page;
